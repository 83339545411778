
import { defineComponent, onMounted } from "vue";
import Survey from "@/components/Survey.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "users-details-result",
  components: {
    Survey,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Survey", ["Users", "Details"]);
    });
  },
});
