
import { defineComponent, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { notify } from "@kyvg/vue3-notification";
import RatingComponent from "@/components/RatingComponent.vue";

export default defineComponent({
  name: "survey",
  components: { RatingComponent },

  setup: function () {
    const router = useRouter();
    const route = useRoute();
    let submitButtonRef = ref<null | HTMLButtonElement>(null);
    let data = ref();
    let userID = ref();
    let page = ref();
    let surveyID = ref();

    onMounted(() => {
      userID.value = Number(route.params.user_id)
        ? route.params.user_id
        : router.push({ name: "users" });
      page.value =
        route.params.page === "users"
          ? `user/${userID.value}/details`
          : route.params.page;
      surveyID.value = Number(route.params.id)
        ? route.params.id
        : router.push({ name: "users" });
      getResults();
    });

    async function getResults() {
      apiService
        .get(`/user/${userID.value}/survey/${surveyID.value}/result`)
        .then(
          await function (resp) {
            if (resp.data.data) {
              data.value = resp.data?.data;
            } else if (resp.data?.code == 401) {
              router.push({ name: "sign-in" });
            }
          }
        )
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    const submitting = (sectionID) => {
      let button = document.getElementById(`section-submit-${sectionID}`);
      button?.setAttribute("data-kt-indicator", "on");
      button?.setAttribute("disabled", "disabled");
      // Activate indicator
      let object = {
        section_id: sectionID,
        user_id: userID.value,
        survey_id: surveyID.value,
        submitted: true,
      };
      apiService
        .put("/survey", object)
        .then(function ({ data }) {
          Swal.fire({
            text: data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          button?.removeAttribute("data-kt-indicator");
          button?.removeAttribute("disabled");
        })
        .catch(function (error) {
          button?.removeAttribute("data-kt-indicator");
          button?.removeAttribute("disabled");

          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };
    const setRating = (resp) => {
      setValue(resp.item, resp.question, resp.value, null);
    };
    const setValue = (sectionID, questionID, value, text) => {
      let object = {
        value: value,
        text: text,
        section_id: sectionID,
        question_id: questionID,
        user_id: userID.value,
        survey_id: surveyID.value,
        max_value: 5,
      };
      apiService
        .put("/survey", object)
        .then(function ({ data }) {
          notify({
            group: "foo-css",
            title: data?.message,
            type: "success",
          });
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };
    return {
      data,
      setValue,
      submitting,
      submitButtonRef,
      page,
      setRating,
    };
  },
});
